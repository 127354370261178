import React from "react"
import { graphql } from "gatsby"

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

import Layout from "../../components/Layout"
import HomeSectionInfinite from "../../components/HomeSectionInfinite"
import Breadcrumbs from "../../components/Breadcrumbs"
import SEO from "../../components/SEO"

const TagTemplate = ({ data }) => {
  const tag = data.tagsPt
  const cards = data.allCardsPt.edges

  const metaDescription =
    tag.description ||
    `Acesse os melhores conteúdos sobre espiritualidade, história e
  sabedorias islâmicas com nossas hashtags`

  return (
    <Layout>
      <SEO
        title={tag.name}
        description={metaDescription}
        card={false}
        canonical={`${process.env.GATSBY_HOST_URL}/pt/hashtags/${tag.slug}`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage={tag.name}
            secondaryLink={"/pt/hashtags"}
            secondaryLinkName="Hashtags"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>{tag.name}</h1>
            <p>
              Acesse os melhores conteúdos sobre espiritualidade, história e
              sabedorias islâmicas com nossas hashtags
            </p>
          </div>

          <HomeSectionInfinite
            name=""
            cards={cards}
            style={{ marginTop: 50, marginBottom: 50 }}
            customClass="home-section-card-list"
          />
        </div>
      </div>
    </Layout>
  )
}

export default TagTemplate

export const query = graphql`
  query($id: String!, $slug: String!) {
    tagsPt(id: { eq: $id }) {
      name
      slug
      createdAt
    }

    allCardsPt(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          name
          slug
          imageUrl
          type
          topic {
            name
            color
          }
          tags {
            name
          }
          meta {
            content
            excerpt
            author
            listFields {
              title
              content
            }
          }
        }
      }
    }
  }
`
